<template>
  <el-card class="box-card full-screen recharge-apply">
    <div ref="pageHead" class="table-page-head">
      <el-form :inline="true" :model="queryForm">
        <el-form-item :label="$t('checkStatus')">
          <el-select v-model="queryForm.status" :placeholder="$t('all')" size="small">
            <el-option :label="$t('all')" value=""></el-option>
            <el-option :label="$t('checkPending')" value="0"></el-option>
            <el-option :label="$t('getApproved')" value="1"></el-option>
            <el-option :label="$t('notApproved')" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="getList" :loading="isLoading"><i class="el-icon-search"></i> {{$t('query')}}</el-button>
        </el-form-item>
      </el-form>
      <el-row class="table-btns">
        <el-button type="text" size="mini" icon="el-icon-edit" @click="openAddApply('transferRechargeApply')">{{$t('transferRechargeApply')}}</el-button>
        <el-button type="text" size="mini" icon="el-icon-edit" @click="openAddApply('cashRechargeApply')">{{$t('cashRechargeApply')}}</el-button>
        <el-button type="text" size="mini" icon="el-icon-edit" @click="openAddApply('withdrawDepositApply')">{{$t('withdrawDepositApply')}}</el-button>
      </el-row>
    </div>

    <div class="data-table" :style="{top:queryTableHeight,height:`calc(100% - ${queryTableHeight} - 70px)`}">
      <el-table :data="tableData.records" v-loading="isLoading" element-loading-background="rgba(255,255,255,0.4)" size="mini" height="100%">
        <el-table-column type="selection" fixed="left" width="50"></el-table-column>
        <el-table-column prop="type" fixed="left" :label="$t('applicationType')" width="160">
          <template slot-scope="scope">
            <span v-if="scope.row.type==0" class="text-primary">{{$t('transferRechargeApply')}}</span>
            <span v-if="scope.row.type==1" class="text-success">{{$t('cashRechargeApply')}}</span>
            <span v-if="scope.row.type==2" class="text-danger">{{$t('withdrawDepositApply')}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="applyAmount" fixed="left" :label="$t('applyAmount')" width="160">
          <template slot-scope="scope">
            <span class="franc">₣</span>{{scope.row.applyAmount.formatMoney(0)}}
          </template>
        </el-table-column>
        <el-table-column prop="status" fixed="left" :label="$t('checkStatus')" width="130">
          <template slot-scope="scope">
            <span v-if="scope.row.status==0" class="text-primary">{{$t('checkPending')}}</span>
            <span v-if="scope.row.status==1" class="text-success">{{$t('getApproved')}}</span>
            <span v-if="scope.row.status==2" class="text-danger">{{$t('notApproved')}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="applyCode" :label="$t('applyCode')" width="160"></el-table-column>
        <el-table-column prop="applyRemark" :label="$t('applayRemark')" min-width="200" show-overflow-tooltip></el-table-column>
        <el-table-column prop="applyTime" :label="$t('applyTime')" width="220"></el-table-column>
        <el-table-column prop="applyUser" :label="$t('applyUser')" width="160"></el-table-column>
        <el-table-column prop="bankName" :label="$t('bankName')" width="160"></el-table-column>
        <el-table-column prop="bankTransferserial" :label="$t('bankTransferserial')" width="160"></el-table-column>
        <el-table-column prop="transfeDate" :label="$t('transfeDate')" width="220"></el-table-column>
        <el-table-column prop="orgInfoId" :label="$t('orgId')" width="160"></el-table-column>

        <el-table-column prop="transactionCode" :label="$t('transactionId')" width="200"></el-table-column>
        <el-table-column prop="transactionInfoId" :label="$t('transactionInfoId')" width="160"></el-table-column>
        <el-table-column prop="verifyTime" :label="$t('verifyTime')" width="220"></el-table-column>
        <el-table-column prop="verifyUser" :label="$t('auditor')" width="160"></el-table-column>
        <el-table-column prop="verifyRemark" :label="$t('checkRemark')" width="160" show-overflow-tooltip></el-table-column>
      </el-table>
    </div>

    <el-pagination background layout="total,sizes,prev,pager,next,jumper" :total="tableData.total||0" :page-sizes="[20, 30, 50, 100, 200]" :page-size="pageSize" :current-page="currentPage" @size-change="setPageSzie" @current-change="setCurrentChange" @prev-click="setCurrentChange" @next-click="setCurrentChange" />

    <el-drawer :title="$t(applyTitle)" :visible.sync="isAddApply" :direction="'rtl'" :modal="false" :destroy-on-close="true" :wrapperClosable="false" :size="700">
      <el-form :model="applyForm" :rules="rules" ref="applyForm">
        <el-form-item :label="$t('applyAmount')" prop="applyAmount">
          <el-input size="medium" v-model="applyForm.applyAmount"></el-input>
        </el-form-item>
        <el-form-item v-if="applyForm.type==0" :label="$t('bankName')" prop="bankName">
          <el-input size="medium" v-model="applyForm.bankName"></el-input>
        </el-form-item>
        <el-form-item v-if="applyForm.type==0" :label="$t('bankTransferserial')" prop="bankTransferserial">
          <el-input size="medium" v-model="applyForm.bankTransferserial"></el-input>
        </el-form-item>
        <el-form-item v-if="applyForm.type==0" :label="$t('transfeDate')" prop="transfeTime">
          <el-date-picker v-model="applyForm.transfeTime" type="datetime" :time-arrow-control="true" :format="'yyyy-MM-dd HH:mm:ss'" :placeholder="$t('selectDateAndTime')" style="width:100%">
          </el-date-picker>
        </el-form-item>
        <el-form-item :label="$t('applayRemark')" prop="remark">
          <el-input type="textarea" size="medium" v-model="applyForm.remark"></el-input>
        </el-form-item>
        <div class="bottom-button">
          <el-button size="medium" type="primary" :loading="isLoading" @click="onSubmitAdd('applyForm')">{{$t('submit')}}</el-button>
        </div>
      </el-form>
    </el-drawer>
  </el-card>
</template>

<script>

import orgInfoApply from '@/api/orgInfoApply'
import manba from '@/assets/uilt/manbaDate'

export default {
  name: "virtualAccountRechargeApply",
  data () {
    return {
      isLoading: false,
      //table高度，动态设置
      queryTableHeight: String,
      //table显示内容
      tableData: {},
      //分页器默认参数
      pageSize: 20,
      currentPage: 1,

      queryForm: {},
      //是否显示新增申请弹框
      isAddApply: false,
      //申请弹框标题  
      applyTitle: "",
      //申请表单
      applyForm: {},

    }
  },
  computed: {
    rules () {
      return {
        type: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        applyAmount: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        bankName: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        bankTransferserial: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        transfeTime: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        businessCode: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
      }
    }
  },
  methods: {
    getList () {
      let _this = this;
      _this.isLoading = true;

      orgInfoApply.getPage({
        param: { current: _this.currentPage, size: _this.pageSize, ..._this.queryForm },
        success: res => {
          _this.isLoading = false;
          _this.tableData = res.data || {};
        }
      })
    },
    setPageSzie (val) {
      this.pageSize = val;
      this.getList();
    },
    setCurrentChange (val) {
      this.currentPage = val;
      this.getList();
    },
    openAddApply (title) {
      this.isAddApply = true;
      this.applyTitle = title;
      if (title == "transferRechargeApply") this.applyForm.type = 0;
      else if (title == "cashRechargeApply") this.applyForm.type = 1;
      else if (title == "withdrawDepositApply") this.applyForm.type = 2;
    },

    /**提交充值申请 */
    onSubmitAdd (refName) {
      let _this = this;
      this.$refs[refName].validate(valid => {
        if (!valid) return false;
        _this[refName].transfeTime = manba(_this[refName].transfeTime).format('ymdhms')
        _this[refName].businessCode = 10
        orgInfoApply.addOrgInfoApply({
          param: _this[refName],
          success: res => {
            _this.isLoading = false;
            if (res.code == 200) {
              _this.$message.success(_this.$t('operateSuccessfully'));
              _this.isAddApply = false;
              _this.getList();
            }
            else
              _this.$message.warning(_this.$t(res.msg));
          }
        })
      })

    }
  },
  mounted () {
    let queryTableHeight = this.$refs.pageHead.offsetHeight + 15;
    this.queryTableHeight = queryTableHeight + 'px';
    this.getList();
  },
}
</script>

<style lang="less" scoped>
.recharge-apply {
  #org-info-from {
    .el-col-8 {
      margin-bottom: 31px;
    }
  }

  .testes {
    animation: identifier 300ms;
  }
  @keyframes identifier {
    0% {
      height: 0px;
    }
    100% {
      height: 100px;
    }
  }
}
</style>